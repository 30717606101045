import React, { useEffect, useState } from "react";
import "./SliderItem.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { fetchDataFromFirestore } from "../hooks/fetchDataFromFirebase";


function SliderItem({ activeButton}) {
  const [data, setData] = useState({
    itemsCrop: [],
    itemsProducts: [],
   
  });
 
  useEffect(() => {
    const fetchAllData = async () => {
      const fetchedItemsCrops = await fetchDataFromFirestore("items");
      const fetchedItemsProducts = await fetchDataFromFirestore(
        "itemsProducts"
      );
     
        
      // Set the fetched data in state
      setData({
        itemsCrop: fetchedItemsCrops,
        itemsProducts: fetchedItemsProducts,
      });
    };
    

    fetchAllData();
  }, []);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 14,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 5,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 2,
    },
  };
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  
  const handleItemClick = (item) => {
    if (data.itemsProducts.includes(item)) {
      setShowPopup(true);
      setSelectedItem(item);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedItem(null);
  };
  return (
    <div className="SliderItem">
      <Carousel
         responsive={responsive}
         containerClass="carousel-container"
         itemClass="carousel-item"
         showDots={false}
         arrows={true}
         renderDotsOutside={true}
         swipeable={true}
         shouldResetAutoplay={false}
         infinite={true} // Ensure infinite scrolling
         autoPlay={true}
         autoPlaySpeed={2000} // Adjust autoPlaySpeed as needed
         pauseOnHover={true}
      >
      {activeButton === 'Crop' ? (
  data.itemsCrop.map((item, index) => (
    <div
      key={index}
      className="ItemShow"
      onClick={() => handleItemClick(item)}
    >
      <Link className="link-item" to={`/${encodeURIComponent(item.title)}`}>
        <img
          className="image-item"
          src={item.image}
          alt={item.imageDescription}
        />
      </Link>
    </div>
  ))
) : (
  data.itemsProducts.map((item, index) => (
    <div
      key={index}
      className="ItemShow"
      onClick={() => handleItemClick(item)}
    >
        <img
          className="image-item"
          src={item.image}
          alt={item.imageDescription}
        />
    </div>
  ))
)}
  </Carousel>

      {showPopup && selectedItem && (
        <div className="popup" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => {e.stopPropagation();closePopup()}}>
            <img className="image-popup" src={selectedItem.imageDescription} alt={selectedItem.imageDescription} />
          </div>
        </div>
      )}
    </div>
  );
}

export default SliderItem;
