import React, { useState, useRef } from "react";
import "./CardSlide.css"; // Create this CSS file for styling

const SliderCard = ({ cards }) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isHover, setIsHover] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleCardChange = (index) => {
    setCurrentCardIndex(index);
  };
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  return (
    <div className="slider-card">
      <div className="slider-card-image">
        <img
          src={
            isHover
              ? cards[currentCardIndex].hoveredImage
              : cards[currentCardIndex].initialImage
          }
          alt="Card"
          className="sliderCardImg"
          onMouseOver={() => {
            setIsHover(true);
          }}
          onMouseOut={() => {
            setIsHover(false);
          }}
          onClick={handleOpenPopup}
        />
        
      </div>

      {/* Card Buttons */}
      <div className="slider-card-buttons">
        {cards.map((_, index) => (
          <button
            key={index}
            className={`card-button ${
              currentCardIndex === index ? "active" : ""
            }`}
            onClick={() => handleCardChange(index)}
          >{index
          +1}</button>
        ))}
      </div>
            
      {isPopupOpen && (
        <div className="popup1" onClick={handleClosePopup}>
          <div className="popup-content1" onClick={(e) => {e.stopPropagation();handleClosePopup()}}>
            <img className="image-popup1" src={cards[currentCardIndex].hoveredImage}  />
          </div>
        </div>
      )}
    </div>
  );
};

export default SliderCard;
