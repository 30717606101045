import { collection, getDocs } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../slider/firebase';
// Function to fetch data from Firestore
export const fetchDataFromFirestore = async (collectionName) => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));
    const fetchedData = [];

    querySnapshot.forEach((doc) => {
      fetchedData.push(doc.data());
    });

    return fetchedData;
  } catch (error) {
    console.error('Error fetching data from Firestore:', error);
    return [];
  }
};
