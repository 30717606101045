import { Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './NavBar/navbar';
import { Home } from './pages/Home';
import { Contact } from './pages/Contact';
import DetailsItem from './pages/Detailsitem';
import { useEffect, useState } from 'react';
import { fetchDataFromFirestore } from './hooks/fetchDataFromFirebase';

function App() {
  const [itemsCrop, setItemsCrop] = useState([]);
  useEffect(() => {
    const fetchItemsCrop = async () => {
      try {
        const fetchedItemsCrops = await fetchDataFromFirestore("items");
        setItemsCrop(fetchedItemsCrops);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchItemsCrop();
  }, []);
  return (
    
    <div className="App">
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/:title' element={<DetailsItem data={itemsCrop} />} />
      </Routes>
    </div>
  );
}

export default App;
