import React, { useState, useEffect } from "react";
import "./Detailsitem.css";
import { useParams } from "react-router-dom";
import { fetchDataFromFirestore } from "../hooks/fetchDataFromFirebase";
import SliderCard from "./CardSlide";

function DetailsItem() {

  const { title } = useParams();
  const [itemsCrop, setItemsCrop] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const fetchItemsCrop = async () => {
      try {
        const fetchedItemsCrops = await fetchDataFromFirestore("items");
        setItemsCrop(fetchedItemsCrops);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchItemsCrop();
  }, []);

  useEffect(() => {
    const foundItem = itemsCrop.find(
      (item) => item.title === decodeURIComponent(title)
    );
    setSelectedItem(foundItem);
  }, [title, itemsCrop]);

  if (!selectedItem) {
    return <div>Item not found</div>;
  }


  // Create an array of cards using the product images from selectedItem
  const cards = selectedItem.products.map((product, index) => ({
    initialImage: product.productImage,
    hoveredImage: product.flipImage, // Add hover images if needed
    isHovered: false,
    
  }
  )
  );

  return (
    <div className="ProductPage">
      <div className="container">
      <img src={selectedItem?.backgroundImage} className="containerImg" alt="" />
        {/* Use the SliderCard component */
        console.log(cards)}
        <SliderCard cards={cards} />
      </div>
    </div>
  );
}

export default DetailsItem;
