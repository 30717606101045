import React from "react";
import "./Navbar.css"; // Import your CSS file for styling
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";


function Navbar() {
  return (
    <>
      <nav className="navbar">
        <div className="left-nav">
          <NavLink className="logo" to="/">
            <img
              className="logo-img"
              src="https://firebasestorage.googleapis.com/v0/b/maxplexpro-d3d67.appspot.com/o/logo-01.png?alt=media&token=231d89bc-f354-4612-b2b9-d86820892e39"
              alt="logo"
            />
          </NavLink>
        </div>
        <div className="right-nav">
          
            
              <a
                href="https://www.facebook.com/example"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  size="xl"
                  style={{ color: "#1877F2" }}
                />
           
            </a>
            <a
              href="https://www.instagram.com/example"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size="xl"
                style={{ color: "#F56040" }}
              />
            </a>
            <a
              href="https://twitter.com/example"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faTwitter}
                size="xl"
                style={{ color: "#1DA1F2" }}
              />
            </a>
            <a
              href="https://www.linkedin.com/in/example"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                size="xl"
                style={{ color: "#0A66C2" }}
              />
            </a>
          

         
            <NavLink to="/contact" className="nav-link">
              <FontAwesomeIcon
                icon={faEnvelope}
                size="2xl"
                style={{ color: "#27aa6d" }}
              />
            </NavLink>
          
        </div>
      </nav>
    </>
  );
}

export default Navbar;
