import React from 'react'
import './Home.css'
import Slider from '../slider/Slider';
import SliderItem from '../slider/SliderItem';



export const Home = () => {
  
  return (

  <div className='div-home'>
   <Slider/>   
  </div>

   );
}