import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Icons({ currentSlide, nextSlide, prevSlide, icons, arrow, method }) {
  // const slideLength = sliderData.length;

  const autoScroll = true;
  let slideInterval;
  let intervalTime = 10000000;

  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  useEffect(() => {
    if (autoScroll) {
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  return (
    <>
      <FontAwesomeIcon
        icon={icons}
        className={arrow}
        onClick={method === "nextSlide" ? nextSlide : prevSlide}
      />
    </>
  );
}

export {Icons};