import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'; 

 const firebaseConfig = {
  apiKey: "AIzaSyDF_l9qWxxT-pEETHoGtFvvKwezTieUOZE",
  authDomain: "maxplexpro-d3d67.firebaseapp.com",
  projectId: "maxplexpro-d3d67",
  storageBucket: "maxplexpro-d3d67.appspot.com",
  messagingSenderId: "455674610504",
  appId: "1:455674610504:web:e67b87441009f422a1e97d",
  measurementId: "G-GKFH5VN8R4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig); // Initialize Firebase
 const db = getFirestore(app);
 export {db};