import React, { useState, useEffect } from "react";
import "./Slider.css";
import {
  faCircleArrowLeft,
  faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Icons } from "../components/Icons";
import SliderItem from "./SliderItem";
import { fetchDataFromFirestore } from "../hooks/fetchDataFromFirebase";
// import DetailsItem from "../pages/Detailsitem";
import Titles from "../components/Titles";

function Slider() {
  const [activeButton, setActiveButton] = useState("Crop");
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState({
    itemsCrop: [],
    sliderData: [],
    itemsProducts: [],
    items: [],
  });
  const [currentSlide, setCurrentSlide] = useState(0); // Add currentSlide state
  const autoScroll = true;
  let slideInterval;
  let intervalTime = 5000;
  const handleImageLoad = () => {
    setIsLoading(false); // Image has loaded
  };

  const handleImageError = () => {
    setIsLoading(false); // Image failed to load
  };
  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }
  useEffect(() => {
    const fetchAllData = async () => {
      const fetchedItemsCrops = await fetchDataFromFirestore("items");
      const fetchedSliderData = await fetchDataFromFirestore("HomeImages");
      const fetchedItemsProducts = await fetchDataFromFirestore(
        "itemsProducts"
      );
      const fetchedItemsbuttons = await fetchDataFromFirestore("itemsbutton");

      // Set the fetched data in state
      setData({
        itemsCrop: fetchedItemsCrops,
        sliderData: fetchedSliderData,
        itemsProducts: fetchedItemsProducts,
        items: fetchedItemsbuttons,
      });
      console.log(data);
    };

    fetchAllData();
  }, []);
  const slideLength = data.sliderData.length;
  console.log(slideLength);

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  };

  // useEffect(() => {
  //   if (autoScroll) {
  //     auto();
  //   }
  //   return () => clearInterval(slideInterval);
  // }, [currentSlide]);

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  // Function to handle button click and update active state and fetch data
  const handleButtonClick = async (itemName) => {
    setActiveButton(itemName);
    if (itemName == "Crop") {
      setData({
        ...data,
        itemsCrop: data.itemsCrop,
        itemsProducts: [],
      });
    } else {
      setData({
        ...data,
        itemsCrop: [],
        itemsProducts: data.itemsProducts,
      });
    }
  };

  return (
    <div>
      <div className="sliderContainer">
        <div className="slider">
          {data.sliderData.map((slide, index) => {
            return (
              <div
                className={index === currentSlide ? "slide current" : "slide"}
                key={index}
              >
                {index === currentSlide && (
                  <div className="homeContiner">
                    <img
                      src={slide.image}
                      alt="slide"
                      className={`slideImg ${isLoading ? "hidden" : ""}`}
                      onLoad={handleImageLoad}
                      onError={handleImageError}
                      loading="lazy"
                    />

                    {/* <div className="content">
                      <h2>{slide.title}</h2>
                    </div> */}
                    <div className="overlay-container">
                      <Icons
                        currentSlide={currentSlide}
                        nextSlide={nextSlide}
                        prevSlide={prevSlide}
                        icons={faCircleArrowRight}
                        arrow="arrow next"
                        method={"nextSlide"}
                      />
                      <Icons
                        currentSlide={currentSlide}
                        nextSlide={nextSlide}
                        prevSlide={prevSlide}
                        icons={faCircleArrowLeft}
                        arrow="arrow prev"
                        method={"prevSlide"}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="buttons">
          <div className="buttonsDiv">
            {data.items.map((item) => (
              <button
                key={item.name}
                className={item.name === activeButton ? "active" : ""}
                style={{
                  backgroundImage: `url(${
                    item.name === activeButton ? item.activeIcon : item.icon
                  })`,
                }}
                onClick={() => handleButtonClick(item.name)} // Add click handler
              >
                {item.name}
              </button>
            ))}
          </div>
          <SliderItem activeButton={activeButton} />
        </div>
      </div>
      <Titles></Titles>
    </div>
  );
}

export default Slider;
