import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
export const Contact = () => {
  // Initialize form state
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email using emailjs
    emailjs
      .send(
        "service_mdp8ije",
        "template_3skmyzo",
        formData,
        "c9MM09FtkMd7aTx0X"
      )
      .then(
        (result) => {
          setFormData({
            fullName: "",
            email: "",
            phoneNumber: "",
            message: "",
          });
        
          console.log("Email sent successfully:", result);
        },
        (error) => {
          console.error("Error sending email:", error);
          
        }
      );
  };
  return (
    <>
      <div className="ContactContainer">
        
        <div className="form">
          <h2>تواصل معنا</h2>
          <form onSubmit={handleSubmit} className="contact-form">
            <div className="form-row">
              <div className="input-group">
                <label className="m-Lable" htmlFor="fullName">
                  الاسم الكامل
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="input-group">
                <label className="m-Lable" htmlFor="email">
                  ايميل
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="input-group">
                <label className="m-Lable" htmlFor="phoneNumber">
                  رقم الهاتف
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="message-group">
              <label className="m-Lable" htmlFor="message">
                رسالة
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="submit">
              <button type="submit">ارسل</button>
            </div>
          </form>
        </div>
        <div className="info">
          <div className="info-details">
            <h2>هيا بنا نتواصل</h2>
            <br />
            <p>نحن هنا للاجابة عن اي سوال يطرح ويمكنكم التواصل معنا عن طريق المعلومات التالية
            </p>
          </div>
          <div>
            <ul>
              <li>
                <FontAwesomeIcon icon={faEnvelope} />
                <p> rateb@futroo.com</p>
              </li>
              <li>
                <FontAwesomeIcon icon={faPhone} /> <p>00972544973616</p>
              </li>
              <li>
                <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                <p>East-Jerusalem-BetHanina</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
