import React, { useState, useEffect } from "react";
import "../slider/Slider.css";
function Titles() {
  const headers = [
    "نباتات أكبر واقوى وأكثر حيوية",
    " الخضراوات والفواكهه تتمتع بالوان طبيعية أكثر",
    "طعم أزكى",
    "إنتاجية أعلى",
    "جودة أفضل",
    " مدة صالحية أطول",
  ];
  const colors = [
    "#3c3c3b",
    "#e30613",
    "#55ac44",
    "#622480",
    "#p32d4f", // This color seems incorrect, I assume it's a typo
    "#f5ae33",
  ];

  const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0);
  useEffect(() => {
    // Set up an interval to change the header every 1 second
    const interval = setInterval(() => {
      setCurrentHeaderIndex((prevIndex) =>
        prevIndex === headers.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ background: colors[currentHeaderIndex] }} className="h1-div">
      <h1>{headers[currentHeaderIndex]}</h1>
    </div>
  );
}

export default Titles;
